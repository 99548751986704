import {imageUrl} from 'common/sanity';
import {breakpoint} from 'common/theme';
import {PageCardGridSize, SanityPageCardGridData} from 'common/types';
import {Col, Row} from 'components/grid';
import {motion} from 'framer-motion';
import Link from 'next/link';
import {FC} from 'react';
import styled from 'styled-components';
import NextImage, {ImageProps} from 'next/image';
// @ts-ignore
import colorAlpha from 'color-alpha';

const Wrapper = styled.section`
  padding: 0 ${({theme}) => theme.spacing(1)}px;
  margin-top: ${({theme}) => theme.spacing(2 + 1.75 * 0.5)}px;
  margin-bottom: ${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    padding: 0 ${({theme}) => theme.spacing(2)}px;
    margin-top: ${({theme}) => theme.spacing(2)}px;
    margin-bottom: ${({theme}) => theme.spacing(2)}px;
  `}
  ${breakpoint.size('md')`
    margin-top: ${({theme}) => theme.spacing(3)}px;
    margin-bottom: ${({theme}) => theme.spacing(3)}px;
  `};
`;

const Background = styled.div<{color: string}>`
  background: ${({color}) => color};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CardItemLink = styled(motion.a)`
  border-radius: 5px;
  overflow: hidden;
  text-decoration: none;
  text-transform: none;
  color: ${({theme}) => theme.colors.white};
  position: relative;
  display: inline-block;
  width: 100%;
  height: 200px;
  ${breakpoint.size('sm')`
    height: 220px;
  `}
  ${breakpoint.size('md')`
    height: 300px;
  `};
  transition: opacity 200ms ease-in-out;
  &:hover {
    opacity: 0.9;
  }
`;

const CardItemContent = styled.div`
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  padding: ${({theme}) => theme.spacing(1)}px;
`;

const Title = styled.div`
  font-family: ${({theme}) => theme.fonts.teko};
  font-size: ${({theme}) => theme.fontSize(1.3)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1.6)}px;
  `}
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(1.9)}px;
  `}
`;

const LinkItem = styled.div`
  font-weight: 200;
  font-family: ${({theme}) => theme.fonts.teko};
  font-size: ${({theme}) => theme.fontSize(1)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1.1)}px;
  `}
  & > span:nth-child(2) {
    margin-left: 0.25em;
  }
`;

const StyledNextImage = styled(NextImage)<ImageProps>`
  z-index: 1;
`;

const getSize = (length: number): PageCardGridSize[] => {
  const SMALL = 2 / 5;
  const LARGE = 3 / 5;

  const result: PageCardGridSize[] = [];

  new Array(length).fill(null).forEach((_, index) => {
    const last = result[index - 1];
    const beforeLast = result[index - 2];
    if (result.length === 0) {
      result.push({xs: 1, md: 1 / 2, lg: SMALL});
    } else if (last.lg === SMALL && result.length === 1) {
      result.push({xs: 1, md: 1 / 2, lg: LARGE});
    } else if (last.lg === LARGE && beforeLast.lg === SMALL) {
      result.push({xs: 1, md: 1 / 2, lg: LARGE});
    } else if (last.lg === SMALL && beforeLast.lg === LARGE) {
      result.push({xs: 1, md: 1 / 2, lg: SMALL});
    } else if (last.lg === SMALL && beforeLast.lg === SMALL) {
      result.push({xs: 1, md: 1 / 2, lg: LARGE});
    } else if (last.lg === LARGE && beforeLast.lg === LARGE) {
      result.push({xs: 1, md: 1 / 2, lg: SMALL});
    }
  });
  return result;
};

const PageCardGrid: FC<SanityPageCardGridData> = props => {
  const size = getSize(props.items.length);
  return (
    <Wrapper>
      <Content>
        <Row>
          {props.items.map((item, index) => {
            return (
              <Col key={index} size={size[index]}>
                <Link href={item.slug.current} passHref>
                  <CardItemLink whileHover={{scale: 1.025}} whileTap={{scale: 0.975}}>
                    <CardItemContent>
                      <Title>{item.title}</Title>
                      <LinkItem>
                        <span>weitere Details</span> <span>→</span>
                      </LinkItem>
                    </CardItemContent>
                    <StyledNextImage
                      layout='fill'
                      objectFit='cover'
                      objectPosition='center'
                      src={
                        imageUrl(item.image)
                          .width(600)
                          .auto('format')
                          .quality(80)
                          .url() || ''
                      }
                      placeholder='blur'
                      blurDataURL={item.image.lqip}
                    />
                    <Background color={colorAlpha(item.image.dominant.background, 0.4)} />
                  </CardItemLink>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Content>
    </Wrapper>
  );
};

export default PageCardGrid;
